import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import parallax from './libraries/parallax';
import '@/assets/styles.css';

Vue.config.productionTip = false

new Vue({
  parallax,
  router,
  vuetify,
  i18n,
  render: h => h(App),
  mounted () { document.dispatchEvent(new Event('render-event')) }
}).$mount('#app')
