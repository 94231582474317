import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#1976d2",
        secondary: "#12181c",
        tertiary: "#1b2429", //colors.blueGrey.darken4,
        contrast: colors.shades.white,
        disabled: colors.shades.white,
        accent: colors.cyan.accent2,
      },
      light: {
        primary: "#1976d2",
        secondary: colors.blueGrey.lighten5, //#ECEFF1
        tertiary: colors.blueGrey.lighten4, //#CFD8DC
        contrast: colors.shades.black,
        accent: colors.cyan.accent2,
      },
    },
  },
});
