<template>
  <nav>
    <!-- Top navbar -->
    <v-app-bar class="navbar" color="secondary" app>
      <!-- Button/icon for left drawer, visible for sizes sm and down -->
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- Logo and Title -->
      <img
        :src="require('../assets/Logo-half-grey.svg')"
        height="35px"
        width="35px"
      />
      <v-toolbar-title class="contrast--text" style="width: 16rem;">
        <span class="font-weight-light">Alex</span>
        <span>Vecchiettini</span>
      </v-toolbar-title>
      <!-- Top navbar central navigation links, visible for sizes md and up -->
      <v-tabs
        v-if="$vuetify.breakpoint.mdAndUp"
        horizontal
        centered
        grow
        color="contrast"
        slider-color="primary"
      >
        <v-tab v-for="link in links" :key="link.text" router :to="link.route">
          {{ $t(link.text) }}
          <v-icon class="ml-3 ml-lg-6 ml-xl-12">{{ link.icon }}</v-icon>
        </v-tab>
      </v-tabs>
      <!-- Button/icon for right settings drawer, visible for sizes md and up -->
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        icon
        class="ml-8"
        @click="settingsDrawer = !settingsDrawer"
      >
        <v-icon color="contrast">mdi-cog</v-icon>
      </v-btn>
      <!--  -->
    </v-app-bar>

    <!-- left drawer for navigation and settings, visible for sizes sm and down -->
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      app
    >
      <v-container class="pa-0">
        <v-list>
          <!-- Navigation links -->
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-action>
              <v-icon color="contrast">{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title color="contrast">{{
                $t(link.text)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Settings section -->
          <v-container class="mt-16 pa-0">
            <!-- Settings divider -->
            <v-list-item>
              <v-list-item-action>
                <v-icon color="contrast">mdi-cog</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title color="contrast">{{
                  $t("nav.settings.settings")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <!-- Dark theme switch and label -->
            <v-list-item center class="ml-2 my-n3">
              <v-list-item-content>
                <v-flex xs7 sm7 class="ml-3">
                  <label>
                    <span color="contrast"
                      >{{ $t("nav.settings.darkTheme") }}
                    </span>
                    <span v-if="$vuetify.theme.dark">{{
                      $t("nav.settings.on")
                    }}</span>
                    <span v-if="!$vuetify.theme.dark">{{
                      $t("nav.settings.off")
                    }}</span>
                  </label>
                </v-flex>
                <v-flex xs3 md3 class="ml-6">
                  <v-switch v-model="$vuetify.theme.dark"></v-switch>
                </v-flex>
              </v-list-item-content>
            </v-list-item>
            <!-- Select and label for i18n -->
            <v-list-item justify-center class="ml-2 mt-n10">
              <v-list-item-content>
                <v-flex xs5 sm5 md5 class="ml-3">
                  <label>
                    <span color="contrast"
                      >{{ $t("nav.settings.language") }}
                    </span>
                  </label>
                </v-flex>
                <v-flex xs5 sm5 md5 class="ml-2">
                  <v-select
                    v-model="$i18n.locale"
                    :items="langs"
                    item-text="language"
                    item-value="abbr"
                  >
                    <template v-slot:item="{ item }">
                      <span>{{ item.language }}</span>
                      <v-img
                        width="2vh"
                        height="1.5vh"
                        class="mx-2"
                        :src="item.image"
                      ></v-img>
                    </template>
                  </v-select>
                </v-flex>
              </v-list-item-content>
            </v-list-item>
            <!--  -->
          </v-container>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <!-- Right drawer for settings, visible for sizes md and up -->
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="settingsDrawer"
      color="secondary"
      temporary
      app
      right
    >
      <v-container class="pa-0">
        <v-list>
          <!-- Icon and 'settings' title -->
          <v-list-item>
            <v-list-item-action>
              <v-icon color="contrast">mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title color="contrast">{{
                $t("nav.settings.settings")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!-- Switch and label for Dark theme -->
          <v-list-item justify-center class="ml-2 mt-n5">
            <v-list-item-content>
              <v-flex sm7 md7 class="ml-3">
                <label>
                  <span>{{ $t("nav.settings.darkTheme") }} </span>
                  <span v-if="$vuetify.theme.dark">{{
                    $t("nav.settings.on")
                  }}</span>
                  <span v-if="!$vuetify.theme.dark">{{
                    $t("nav.settings.off")
                  }}</span>
                </label>
              </v-flex>
              <v-flex sm3 md3 class="ml-6">
                <v-switch v-model="$vuetify.theme.dark"></v-switch>
              </v-flex>
            </v-list-item-content>
          </v-list-item>
          <!-- Select and label for i18n -->
          <v-list-item justify-center class="ml-2 mt-n10">
            <v-list-item-content>
              <v-flex sm5 md5 class="ml-3">
                <label>
                  <span>{{ $t("nav.settings.language") }} </span>
                </label>
              </v-flex>
              <v-flex sm5 md5 class="ml-2">
                <v-select
                  v-model="$i18n.locale"
                  :items="langs"
                  item-text="language"
                  item-value="abbr"
                >
                  <template v-slot:item="{ item }">
                    <span>{{ item.language }}</span>
                    <v-img
                      width="2vh"
                      height="1.5vh"
                      class="mx-2"
                      :src="item.image"
                    ></v-img>
                  </template>
                </v-select>
              </v-flex>
            </v-list-item-content>
          </v-list-item>

          <!--  -->
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <!--  -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      settingsDrawer: false,
      langs: [
        { language: "English", abbr: "en", image: require("@/assets/en.svg") },
        { language: "Italiano", abbr: "it", image: require("@/assets/it.svg") },
      ],
      links: [
        { icon: "mdi-home", text: "nav.home", route: "/" },
        { icon: "mdi-file-account", text: "nav.resume", route: "/resume" },
        {
          icon: "mdi-iframe-variable",
          text: "nav.projects",
          route: "/projects",
        },
        {
          icon: "mdi-book-open-variant",
          text: "nav.courses",
          route: "/courses",
        },
        {
          icon: "mdi-human-greeting-proximity",
          text: "nav.contacts",
          route: "/contacts",
        },
      ],
    };
  },
};
</script>
