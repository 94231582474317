<template>
  <v-card
    class="no-overflow"
    :class="item.class"
    :height="item.height"
  >
    <v-img
      v-if="item.image != 'none'"
      :style="item.imageStyle"
      :class="item.imageClasses"
      :src="item.image"
    ></v-img>
    <v-layout align-center justify-center fill-height class="text-container">
      <v-row no-gutters fill-height :justify="item.justify">
        <v-col
          :order="item.left.order"
          :order-md="item.left.orderMd"
          :cols="item.left.cols"
          :md="item.left.md"
          :lg="item.left.lg"
          :xl="item.left.xl"
          v-if="item.left.isOn"
          align-center
          justify-center
        >
          <v-card
            align-center
            justify-center
            :class="item.left.textCardContainerClasses"
            :color="item.left.textCardContainerColor"
          >
            <v-expand-transition v-if="item.left.overlayOn">
              <template v-if="overlay">
                <v-card
                  :class="item.left.overlayCardClasses"
                  style="height: 100%;"
                  :color="item.left.overlayColor"
                >
                  <v-card-text
                    :class="item.left.overlayContentClasses"
                    v-html="$t(item.left.overlayContent)"
                  ></v-card-text>
                </v-card>
              </template>
            </v-expand-transition>
            <v-card-title
              v-if="item.left.title != 'none'"
              :class="item.left.titleClasses"
              v-html="$t(item.left.title)"
            ></v-card-title>
            <v-card-subtitle
              v-if="item.left.subtitle != 'none'"
              :class="item.left.subtitleClasses"
              v-html="$t(item.left.subtitle)"
            ></v-card-subtitle>
            <v-card-text
              v-if="item.left.content != 'none'"
              :class="item.left.contentClasses"
              v-html="$t(item.left.content)"
            ></v-card-text>
            <v-img
              :height="item.left.imageHeight"
              :position="item.left.imagePosition"
              v-if="item.left.image != 'none'"
              :class="item.left.imageClasses"
              :src="item.left.image"
            ></v-img>
            <v-btn
              class="ma-4"
              outlined
              v-if="item.right.overlayOn"
              @click="overlay = !overlay"
              >{{ !overlay ? "Tech details" : "Hide details" }}</v-btn
            >
          </v-card>
        </v-col>

        <v-col
          :order="item.right.order"
          :order-md="item.right.orderMd"
          :cols="item.right.cols"
          :md="item.right.md"
          :lg="item.right.lg"
          :xl="item.right.xl"
          v-if="item.right.isOn"
          align-center
          justify-center
        >
          <v-card
            align-center
            justify-center
            :class="item.right.textCardContainerClasses"
            :color="item.right.textCardContainerColor"
          >
            <v-expand-transition v-if="item.right.overlayOn">
              <template v-if="overlay">
                <v-card
                  :class="item.right.overlayCardClasses"
                  style="height: 100%;"
                  :color="item.right.overlayColor"
                >
                  <v-card-text
                    :class="item.right.overlayContentClasses"
                    v-html="$t(item.right.overlayContent)"
                  ></v-card-text>
                </v-card>
              </template>
            </v-expand-transition>
            <v-card-title
              v-if="item.right.title != 'none'"
              :class="item.right.titleClasses"
              v-html="$t(item.right.title)"
            ></v-card-title>
            <v-card-subtitle
              v-if="item.right.subtitle != 'none'"
              :class="item.right.subtitleClasses"
              v-html="$t(item.right.subtitle)"
            ></v-card-subtitle>
            <v-card-text
              v-if="item.right.content != 'none'"
              :class="item.right.contentClasses"
              v-html="$t(item.right.content)"
            ></v-card-text>
            <v-img
              :height="item.right.imageHeight"
              :position="item.right.imagePosition"
              v-if="item.right.image != 'none'"
              :class="item.right.imageClasses"
              :src="item.right.image"
            ></v-img>
            <v-btn
              class="ma-4"
              outlined
              v-if="item.left.overlayOn"
              @click="overlay = !overlay"
              >{{ !overlay ? "Tech details" : "Hide details" }}</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Slide",
  data: () => ({overlay: false}),
  props: ["item"],
};
</script>
