<template>
  <div>
    <Slides v-bind:scrollingCards="scrollingCards" />
  </div>
</template>

<script>
import Slides from "@/components/Slides";

export default {
  name: "Home",

  components: { Slides },

  methods: {
    // imageClasses(classes) {
    //   if (classes == "variable") {
    //     switch (this.$vuetify.breakpoint.name) {
    //       case "xs":
    //         return "normal-image";
    //       case "sm":
    //         return "normal-image";
    //       case "md":
    //         return "normal-image";
    //       case "lg":
    //         return "parallax parallax-right";
    //       case "xl":
    //         return "parallax parallax-right";
    //     }
    //   } else {
    //     return classes;
    //   }
    // },
  },

  data: () => ({
    // Description of the Cards to show in the page
    scrollingCards: [
      {
        id: "1",
        class: "secondary fill-height",
        layoutColor: "",
        height: "[$vuetify.breakpoint.mdAndUp ? '93vh' : '186vh']",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 6,
          lg: 5,
          xl: 5,
          textCardContainerClasses: "elevation-0 mt-4 mt-md-8",
          textCardContainerColor: "transparent",
          title: "home.slide1.title",
          titleClasses:
            "justify-start contrast--text text-size-3 font-weight-light",
          subtitle: "home.slide1.subtitle",
          subtitleClasses:
            "text-no-wrap text-left primary--text text-size-1 font-weight-light",
          content: "home.slide1.content",
          contentClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 4,
          lg: 4,
          xl: 4,
          textCardContainerClasses: "elevation-0",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          imageHeight: "93vh",
          imagePosition: "top",
          image: require("../assets/home_page_power_pose.webp"),
          imageClasses: "parallax parallax-right ",
        },
      },
      {
        id: "2",
        class: "bg-fixed bg-image-IT",
        height: "70vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
      },
      {
        id: "3",
        class: "tertiary flat elevation-0 py-8",
        height: "",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 3,
          xl: 2,
          textCardContainerClasses: "elevation-0 mt-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "home.slide3.title",
          titleClasses: "text-no-wrap text-size-2 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "elevation-0 mb-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "home.slide3.content",
          contentClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          image: "none",
          imageClasses: "",
        },
      },
      {
        id: "4",
        class: "bg-fixed bg-image-CC",
        height: "70vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
      },
      {
        id: "5",
        class: "tertiary flat elevation-0 py-8",
        height: "",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 3,
          xl: 2,
          textCardContainerClasses: "elevation-0 mt-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "home.slide5.title",
          titleClasses: "text-no-wrap text-size-2 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "elevation-0 mb-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "home.slide5.content",
          contentClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          image: "none",
          imageClasses: "",
        },
      },
      {
        id: "6",
        class: "bg-fixed bg-image-next",
        height: "70vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
      },
      {
        id: "7",
        class: "tertiary flat elevation-0 py-8",
        height: "",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 3,
          xl: 2,
          textCardContainerClasses: "elevation-0 mt-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "home.slide7.title",
          titleClasses: "text-no-wrap text-size-2 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
          image: "none",
          imageClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "elevation-0 mb-16 my-md-16",
          textCardContainerColor: "transparent",
          title: "none",
          titleClasses: "",
          subtitle: "home.slide7.subtitle",
          subtitleClasses:
            "text-left contrast--text text-size-3 font-weight-light",
          content: "home.slide7.content",
          contentClasses:
            "text-left primary--text font-italic text-size-3 font-weight-light",
          image: "none",
          imageClasses: "",
        },
      },
    ],
  }),
};
</script>
