<template>
  <div class="Home">
    <Slide 
          :key="item.id" 
          v-for="item in scrollingCards" 
          v-bind:item="item" />
  </div>
</template>

<script>
import Slide from "./Slide.vue";
export default {
  name: "Slides",
  components: { Slide },
  props: ["scrollingCards"],
};
</script>
