let images = [];
let imagesInitialYOffset = [];
let navbar;
let navbarHeight;

window.addEventListener("scroll", scrollLoop, true);

window.onbeforeunload = function() {
  window.scrollTo(0, 0);
};

window.onload = () => {  
  // array of elements of class "parallax"
  images = document.getElementsByClassName("parallax");
  navbar = document.getElementsByClassName("navbar")[0];
  // claculate offset of the outer element to account for the Navbar offset
  navbarHeight = navbar.getBoundingClientRect().bottom;
  
  images.forEach((image, index) => {
    imagesInitialYOffset[index] = image.getBoundingClientRect().top - navbarHeight;
  })
}

// create parallax effect for elements of class "parallax"
// by dynamically adjusting the top positioning at scroll
// at a reduce speed compared to other elements
// catch the resize to reset the images position
// window.addEventListener('resize', resetParallax, false);
function scrollLoop() {
  // console.log("ole");
  let yScrollPosition = window.scrollY;
  // cycle through all the elements that need adjusting
  images.forEach((image, index) => {
    // Calculate initial image offset as the difference
    // from the initial top position and the parent top position
    // (Please Note: this is calculated only once at the very first scroll to get the actual initial offset)
    if (!imagesInitialYOffset[index]) {
      // console.log(image.getBoundingClientRect().top);
      imagesInitialYOffset[index] =
        image.getBoundingClientRect().top - navbarHeight;
    }
    // Adjust the top position of the element multiplying the scroll
    // (subtracted the offset) by a different speed
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  k > 1        scrolling faster than the rest of the page                                                                                                         //
    //  0 < k < 1    scrolling slower than the rest of the page <---- right values for a parallax effect of an object at higher distance than the other page elements   //
    //  k == 0       no scrolling at all                                                                                                                                //
    //  -1 < k < 0   scrolling slower than the rest of the page in the opposite direction                                                                               //
    //  k < -1       scrolling faster than the rest of the page in the opposite direction                                                                               //
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const k = 0.4;
    setTranslate((yScrollPosition * k), image);
  });
  // requestAnimationFrame(scrollLoop);
}

function setTranslate(yPos, el) {
  el.style.transform = "translateY(" + yPos + "px)";
}

// function resetParallax(){
//   location.reload();
//   // window.scrollTo(0,0);
//   imagesInitialYOffset = [];
//   // scrollLoop();
// }
